import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 500px;
  height: 500px;
  margin: auto;
  z-index: 3;
  animation: fade-in 1s ease-out forwards;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-height: 700px) {
    /* top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform-origin: left top; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: scale(0.8); */
  }

  @media screen and (max-height: 600px) {
    position: absolute;
    top: 20%;

  }

  @media screen and (max-height: 500px) {
    position: absolute;
    top: 30%;
    transform: scale(0.9);
  }

  @media screen and (max-height: 400px) {
    position: absolute;
    top: 30%;
    transform: scale(0.7);
  }

  @media screen and (max-height: 300px) {
    position: absolute;
    top: 30%;
    transform: scale(0.6);
  }

  @media screen and (max-height: 200px) {
    position: absolute;
    top: 30%;
    transform: scale(0.5);
  }

  @media screen and (max-height: 375px) and (max-width: 700px) {
    transform: scale(0.6);
  }


`

export const MessageContainer = styled.img`
  width: 226px;
  height: 260px;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: auto;
`

export const LineWinContainer = styled.img`
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  margin: auto;
  width: 160px;
  height: 140px;
`

export const LineWinNumberContainer = styled.div`
  font-family: "Carnevalee Freakshow";
  position: absolute;
  top: 80px;
  margin: auto;
  font-size: 50px;
  color: #fefefd;
  right: 278px;
  display: inline-block;
  text-shadow: 0px 8px 6.9px rgb(104 0 0 / 80%);
`

export const PrizeMoneyContainer = styled.img`
  width: 270px;
  height: 85px;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  margin: auto;
`

export const RayContainer = styled.img`
  width: 400px;
  height: 400px;
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  animation: rayrotate 5s linear infinite;

  @keyframes rayrotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const CountUpContainer = styled.div`
  width: 270px;
  height: 85px;
  position: absolute;
  top: 240px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FullCardMessage = styled.div`
  font-family: "Carnevalee Freakshow";
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  height: 140px;
  font-size: 60px;
  color: #fefefd;
  text-shadow: 0px 8px 6.9px rgb(104 0 0 / 80%);
`